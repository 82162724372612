    .App {
        text-align: center;
    }
    
    .App-logo {
        height: 40vmin;
        pointer-events: none;
    }
    
    @media (prefers-reduced-motion: no-preference) {
        .App-logo {
            animation: App-logo-spin infinite 20s linear;
        }
    }
    
    .App-header {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .App-link {
        color: #61dafb;
    }
    
    @keyframes App-logo-spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    
    body {
        background-color: #4c3a51;
    }
    
    .navbar-brand {
        width: 15%;
    }
    
    .navbar-brand img {
        width: 100%;
    }
    
    #properties {
        display: none;
    }
    
    .sliderContaine {
        width: 100%;
    }
    
    .slider {
        width: 59.1%;
        /* padding: 0.5%; */
        height: 33px;
        background-color: #B25068;
        -webkit-appearance: none;
        border-radius: 19px;
        /* margin-top: 10%; */
    }
    
    .slider:hover {
        opacity: 99%;
    }
    
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 40px;
        height: 40px;
        background-color: #E7Ab79;
        border-radius: 50%;
        cursor: pointer;
    }
    
    .scale {
        display: flex;
        padding-left: 19.4%;
        padding-right: 13.9%
    }
    
    .line {
        /* width: 0.1%; */
        height: 20px;
        background-color: #B25068;
        margin-left: 5.1%;
        padding: 1px;
        display: flex;
    }
    
    #ten {
        margin-left: 3.5%;
    }
    
    #tenp {
        margin-left: 14%;
    }
    
    #thundp {
        margin-left: 16%;
    }
    
    #twohund {
        margin-left: 4%;
    }
    
    #inline {
        height: 10px;
        background-color: #B25068;
        /* margin-left: 8.5%; */
    }
    
    .above_scale {
        width: 57%;
        margin: auto;
        display: flex;
        column-count: 4;
        column-rule-style: dotted red;
        column-rule-width: 9px;
        margin-top: 2.5%;
        /* border-right: 5px dashed red; */
    }
    
    .above_scale p {
        padding: 22px;
        padding-right: 37px;
        color: white;
        opacity: 70%;
        border-right: dashed #774360;
    }
    
    #value_of_freq {
        margin-top: 2.1%;
        color: #E7Ab79;
        font: x-large;
    }
    
    .above_scale pl {
        padding: 22px;
        color: white;
        padding-top: 20px;
        opacity: 70%;
    }
    
    #btn {
        background: #4c3a51;
        border: 0px;
        margin-top: 2%;
    }
    
    #btn1 {
        width: 15%;
    }
    
    #value_of_freq2 {
        display: none
    }
    
    .sacle_value {
        display: flex;
        padding-left: 9.8%;
        padding-right: 18.9%
    }
    
    .sacle_value p {
        margin-left: 15.2%;
        padding: 1px;
        display: flex;
        color: #B25068;
        font-weight: bold;
    }
    
    .nav-link {
        color: white;
        opacity: 0.5;
        font-size: 20px;
    }
    
    .about_page {
        background-image: linear-gradient(250deg, #f44336 0%, #f2295b 94%);
        background-size: cover;
        padding: 6%;
        opacity: 0.91;
        transition: background .3s, border-radius .3s, opacity .3s;
    }
    
    #about_title {
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 700;
        font-size: 60px;
        padding: 4%;
    }
    
    .hr {
        width: 100px;
        background-color: white;
        height: 5px;
        display: flex;
        margin-left: 45%;
    }
    
    .about_page p {
        font-size: 34px;
        padding: 4%;
        padding-right: 31%;
        padding-left: 30%;
        color: white;
    }
    
    .about_page2 {
        display: flex;
        padding: 5%;
        background-color: white
    }
    
    .contain1 {
        width: 30%;
        background-color: aliceblue;
        padding: 7%;
        border-radius: 10px;
        margin: auto;
        border-color: rgb(190, 190, 190);
        border: 1px solid;
        box-shadow: 1%;
    }
    
    #card {
        text-decoration: none;
        text-decoration: none;
        color: green;
        font-size: 29px;
    }
    
    #card a:link {
        text-decoration: none;
        text-decoration: none;
        color: green;
        font-size: 29px;
    }
    
    #card1 p {
        text-decoration: underline;
        color: green;
        font-size: 29px;
    }
    
    #card2 p {
        text-decoration: underline;
        color: rgb(98, 197, 236);
        font-size: 29px;
    }
    
    #card3 p {
        text-decoration: underline;
        color: rgb(174, 7, 7);
        font-size: 29px;
    }
    
    .contain2 {
        width: 30%;
        background-color: aliceblue;
        padding: 9.4%;
        border-radius: 10px;
        margin: auto;
        border-color: rgb(190, 190, 190);
        border: 1px solid;
    }
    
    .contain1 p {
        font-size: 24px;
        padding: 1%;
        color: grey;
    }
    
    .contain2 p {
        font-size: 24px;
        padding: 1%;
        color: grey;
    }
    
    .about_page3 {
        background-color: white;
        opacity: 2.4;
    }
    
    .about_page3 h1 {
        font-size: 50px;
        padding: 4.4%;
        color: red;
    }
    
    .story {
        display: flex;
        font-size: 20px;
        color: rgb(188, 177, 177);
        font-weight: 600;
        padding: 8%;
        margin: auto;
        padding-top: 2%;
    }
    
    .left {
        padding: 2%;
    }
    
    .footer {
        background-color: rgb(239, 239, 239);
        padding: 2%
    }
    
    .footer p {
        font-size: 16px;
        font-weight: 500;
        color: grey;
    }
    
    @media (max-width: 952px) {
        #thundp {
            margin-left: 6%;
        }
        #tenp {
            margin-left: 4%;
        }
        #about_title {
            font-size: 40px;
        }
        .hr {
            margin-left: 35%;
        }
        .about_page p {
            font-size: 20px;
            padding: 4%;
            padding-right: 27%;
            padding-left: 25%;
            color: white;
        }
        .about_page2 {
            display: block;
            /* padding: 6%; */
            background-color: white;
        }
        .contain1 {
            width: 80%;
            background-color: aliceblue;
            padding: 10%;
            border-radius: 10px;
            border-color: rgb(190, 190, 190);
            border: 1px solid;
            margin: 5%;
            margin-left: 9.5%;
        }
        .contain2 {
            width: 80%;
            background-color: aliceblue;
            padding: 14%;
            border-radius: 10px;
            margin: auto;
            border-color: rgb(190, 190, 190);
            border: 1px solid;
            margin: 5%;
            margin-left: 9.5%;
        }
        .story {
            display: block;
            font-size: 20px;
            color: rgb(188, 177, 177);
            font-weight: 600;
            padding: 11%;
            margin: auto;
            padding-top: 2%;
        }
    }
    
    @media (max-width: 952px) {
        .scale {
            padding-left: 6%;
            padding-right: 8.4%;
        }
        .above_scale {
            display: none;
        }
        .navbar-brand {
            width: 35%;
        }
        .slider {
            width: 90%;
            margin-top: 35%;
        }
        .line {
            margin-left: 5.6%;
        }
        .scale {
            padding-left: 3.5%;
            padding-right: 8.4%;
        }
        #value_of_freq2 {
            display: block;
            color: #E7Ab79;
            font: x-large;
            font-size: 70px;
            padding-top: 8%;
        }
        #value_of_freq {
            display: none;
        }
        #properties {
            display: block;
            height: 80px;
            /* overflow: scroll; */
            color: white;
            /* padding: 1px; */
            font-size: 20px;
            opacity: 70%;
            padding: 22px;
            /* height: 10%; */
        }
        #btn1 {
            width: 20%;
            margin-top: 14%;
        }
        .sacle_value {
            padding-left: 1%;
            padding-right: 13%;
        }
        .sacle_value p {
            margin-left: 7%;
            margin-right: 4%;
            padding: 0px;
            font-size: 16px;
            display: flex;
            color: #B25068;
            font-weight: bold;
        }
    }